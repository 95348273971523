<!-- <select
  required
  #currency="ngModel"
  [(ngModel)]="value"
  id="currency"
  name="currency"
  autocomplete="currency"
  class="text-base border-grey-color py-2 px-4 w-full border-1 border-round-md" 
>
  <option *ngFor="let currency of currencies" [value]="currency.currencyCode">
    {{ currency.currencyCode }}({{ currency.currencySymbol }})
  </option>
</select> -->
  <kendo-dropdownlist required
  #currency="ngModel"
  [(ngModel)]="value"
  [valuePrimitive]="true"
  [data]="currencies"
  textField="currencyCode"
  valueField="currencyCode"
  id="currency"
  name="currency"
  autocomplete="currency">
  <ng-template kendoDropDownListItemTemplate let-currencies>
    <span class="template">{{ currencies.currencyCode }}</span>({{ currencies.currencySymbol }})
  </ng-template></kendo-dropdownlist>

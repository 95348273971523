import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './pipes/safe.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { EncodeUriComponentPipe } from './pipes/encode-uri-component.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ScrollIntoViewDirective } from './directives/scroll-into-view.directive';
import { MustMatchDirective } from './directives/must-match.directive';
import { DateCompareDirective } from './directives/date-compare.directive';
import { AlphanumericDirective } from './directives/alphanumeric.directive';
import { AutoFocusInputDirective } from './directives/auto-focus-input.directive';
import { NumberCompareDirective } from './directives/number-compare.directive';
import { DobValidatorDirective } from './directives/dob-validator.directive';
import { MomentModule } from 'ngx-moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapToArrayFilter } from './pipes/map-to-array.pipes';
// import { OrderByComponent } from './components/order-by/order-by.component';
// import { DateRangeComponent } from './components/date-range/date-range.component';
// import { NumberRangeComponent } from './components/number-range/number-range.component';
// import { CurrencySelectComponent } from './components/currency-select/currency-select.component';
// import { LearnCourseCardHorizontalComponent } from './components/learn-course-card-horizontal/learn-course-card-horizontal.component';
// import { LearnCourseCardVerticalComponent } from './components/learn-course-card-vertical/learn-course-card-vertical.component';
// import { CourseShowInterestButtonComponent } from './components/course-show-interest-button/course-show-interest-button.component';
// import { LearnCourseEnrollButtonComponent } from './components/learn-course-enroll-button/learn-course-enroll-button.component';
import { RouterModule } from '@angular/router';
// import { MentorCourseCardHorizontalComponent } from './components/mentor-course-card-horizontal/mentor-course-card-horizontal.component';
// import { MentorCourseCardVerticalComponent } from './components/mentor-course-card-vertical/mentor-course-card-vertical.component';
// import { SeminarCardVerticalComponent } from './components/seminar-card-vertical/seminar-card-vertical.component';
// import { SemniarCardHorizontalComponent } from './components/semniar-card-horizontal/semniar-card-horizontal.component';
// import { MentorCourseShowInterestButtonComponent } from './components/mentor-course-show-interest-button/mentor-course-show-interest-button.component';
import { MentorCourseEnrollButtonComponent } from './components/mentor-course-enroll-button/mentor-course-enroll-button.component';
// import { SeminarShowInterestButtonComponent } from './components/seminar-show-interest-button/seminar-show-interest-button.component';
import { SeminarEnrollButtonComponent } from './components/seminar-enroll-button/seminar-enroll-button.component';
import { TruncateTitleStringPipe } from './pipes/truncate-title-string.pipe';
// import { PopupSuccessComponent } from './components/popups/popup-success/popup-success.component';
// import { PopupErrorComponent } from './components/popups/popup-error/popup-error.component';
// import { PopupWarningComponent } from './components/popups/popup-warning/popup-warning.component';
// import { PopupJoinComponent } from './components/popups/popup-join/popup-join.component';
// import { PopupRecommendComponent } from './components/popups/popup-recommend/popup-recommend.component';
// import { PopupExploreComponent } from './components/popups/popup-explore/popup-explore.component';
// import { PopupDiscountComponent } from './components/popups/popup-discount/popup-discount.component';
// import { PopupConfirmationComponent } from './components/popups/popup-confirmation/popup-confirmation.component';
// import { PopupLoadingComponent } from './components/popups/popup-loading/popup-loading.component';
// import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
// import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
// import { SignNdaComponent } from './components/sign-nda/sign-nda.component';
// import { PopupVideoComponent } from './components/popups/popup-video/popup-video.component';
import { WordCountPipe } from './pipes/wordcount.pipe';
// import { ProfileSummaryPopupComponent } from './components/profile-summary-popup/profile-summary-popup.component';
// import { VideoPlayerComponent } from './components/video-player/video-player.component';
// import { BlogClapButtonComponent } from './components/blog-clap-button/blog-clap-button.component';
import { SafeHtmlPipe } from './pipes/SafeHtml.pipe';
// import { ShareDialogComponent } from './components/share-dialog/share-dialog.component';
import { MinuteSecondsPipe } from './pipes/miniutes-to-hours.pipe';
// import { SitemapComponent } from '../sitemap/sitemap.component';
import { DynamicImageSrcPipe } from './pipes/dynamic-image-src.pipe';
import { EncodeTitleStringPipe } from './pipes/encode-title-string.pipe';
import { CompressedImageSrcPipe } from './pipes/compressed-image-src.pipe';
// import { CreateCommunitySuggestionsComponent } from './components/create-community-suggestions/create-community-suggestions.component';
// import { ProfileShareDailogComponent } from './components/profile-share-dailog/profile-share-dailog.component';
// import { MoreFromUserCoursesLearnComponent } from '../learn/components/more-from-user-courses-learn/more-from-user-courses-learn.component';
// import { MoreFromUserCoursesMentorComponent } from '../mentor/components/more-from-user-courses-mentor/more-from-user-courses-mentor.component';
// import { MoreFromUserSeminarComponent } from '../seminar/components/more-from-user-seminar/more-from-user-seminar.component';
// import { MoreFromUserBlogComponent } from '../blog/components/more-from-user-blog/more-from-user-blog.component';
// import { MoreFromUserCommunityComponent } from '../communinty/components/more-from-user-community/more-from-user-community.component';
// import { SimilarContentViewLearnComponent } from './components/similar-content/pages/similar-content-view-learn/similar-content-view-learn.component';
// import { SimilarContentViewMentorComponent } from './components/similar-content/pages/similar-content-view-mentor/similar-content-view-mentor.component';
// import { SimilarContentViewSeminarComponent } from './components/similar-content/pages/similar-content-view-seminar/similar-content-view-seminar.component';
// import { SimilarContentViewBlogComponent } from './components/similar-content/pages/similar-content-view-blog/similar-content-view-blog.component';
// import { SimilarContentViewCommunityComponent } from './components/similar-content/pages/similar-content-view-community/similar-content-view-community.component';
// import { LearnCourseCardSimilarContentComponent } from './components/similar-content/components/learn-course-card-similar-content/learn-course-card-similar-content.component';
// import { MentorCourseCardSimilarContentComponent } from './components/similar-content/components/mentor-course-card-similar-content/mentor-course-card-similar-content.component';
// import { SeminarCardSimilarContentComponent } from './components/similar-content/components/seminar-card-similar-content/seminar-card-similar-content.component';
// import { BlogCardSimilarContentComponent } from './components/similar-content/components/blog-card-similar-content/blog-card-similar-content.component';
// import { CommunityCardSimilarContentComponent } from './components/similar-content/components/community-card-similar-content/community-card-similar-content.component';
// import { SimilarProfileViewComponent } from './components/similar-profile/pages/similar-profile-view/similar-profile-view.component';
// import { LearnCourseCardSimilarProfileComponent } from './components/similar-profile/components/similar-profile-card/similar-profile-card.component';
// import { OneTapComponent } from './components/one-tap/one-tap.component';
// import { EmptyCardComponent } from './components/empty-card/empty-card.component';
import { AvatarFallbackPipe } from './pipes/avatar-fallback.pipe';
import { ImageFallbackPipe } from './pipes/image-fallback.pipe';
// import { LearnCourseCardComponent } from '../search/components/learn-course-card/learn-course-card.component';
// import { MentorCourseCardComponent } from '../search/components/mentor-course-card/mentor-course-card.component';
// import { SeminarCardComponent } from '../search/components/seminar-card/seminar-card.component';
// import { ArticalCardComponent } from '../search/components/artical-card/artical-card.component';
// import { CommunityCardComponent } from '../search/components/community-card/community-card.component';
// import { SkeletonCourseCardComponent } from '../search/components/skeleton-course-card/skeleton-course-card.component';
// import { SkeletonCommunityComponent } from '../search/components/skeleton-community/skeleton-community.component';
// import { SkeletonArticalComponent } from '../search/components/skeleton-artical/skeleton-artical.component';
// import { CommonErrorComponent } from './components/common-error/common-error.component';
// import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
// import { ProjectCardComponent } from '../search/components/project-card/project-card.component';
import { DynamicImageHandlerPipe } from './pipes/dynamic-image-handler.pipe';
import { DynamicImageDirective } from './directives/dynamic-image.directive';
import { KendoModule } from '../kendo.module';
import { CurrencySelectComponent } from './components/currency-select/currency-select.component';
import { CourseEnrollButtonComponent } from './components/course-enroll-button/course-enroll-button.component';
// import { ProjectCardComponent } from '../search/components/project-card/project-card.component';

@NgModule({
  declarations: [
    //directives
    ClickOutsideDirective,
    ScrollIntoViewDirective,
    MustMatchDirective,
    DateCompareDirective,
    AlphanumericDirective,
    AutoFocusInputDirective,
    NumberCompareDirective,
    DobValidatorDirective,
    DynamicImageDirective,
    //pipes
    SafePipe,
    OrderByPipe,
    EncodeUriComponentPipe,
    MapToArrayFilter,
    TruncateTitleStringPipe,
    WordCountPipe,
    // SafeHtmlPipe,
    MinuteSecondsPipe,
    DynamicImageSrcPipe,
    // DynamicImageHandlerPipe,
    EncodeTitleStringPipe,
    CompressedImageSrcPipe,
    AvatarFallbackPipe,
    ImageFallbackPipe,
    //components
    // DateRangeComponent,
    // NumberRangeComponent,
    // OrderByComponent,
    CurrencySelectComponent,
    CourseEnrollButtonComponent,
    // LearnCourseCardHorizontalComponent,
    // LearnCourseCardVerticalComponent,
    // CourseShowInterestButtonComponent,
    // LearnCourseEnrollButtonComponent,
    // MentorCourseCardHorizontalComponent,
    // MentorCourseCardVerticalComponent,
    // SeminarCardVerticalComponent,
    // SemniarCardHorizontalComponent,
    // MentorCourseShowInterestButtonComponent,
    MentorCourseEnrollButtonComponent,
    // SeminarShowInterestButtonComponent,
    SeminarEnrollButtonComponent,
    // PopupSuccessComponent,
    // PopupErrorComponent,
    // PopupWarningComponent,
    // PopupJoinComponent,
    // PopupRecommendComponent,
    // PopupExploreComponent,
    // PopupDiscountComponent,
    // PopupConfirmationComponent,
    // PopupLoadingComponent,
    // PopupVideoComponent,
    // LoadingScreenComponent,
    // ComingSoonComponent,
    // SignNdaComponent,
    // ProfileSummaryPopupComponent,
    // VideoPlayerComponent,
    // BlogClapButtonComponent,
    // ShareDialogComponent,
    // CommunityBookmarkButtonComponent,
    // SitemapComponent,
    // CreateCommunitySuggestionsComponent,
    // ProfileShareDailogComponent,
    // MoreFromUserCoursesLearnComponent,
    // MoreFromUserCoursesMentorComponent,
    // MoreFromUserSeminarComponent,
    // MoreFromUserBlogComponent,
    // MoreFromUserCommunityComponent,
    // SimilarContentViewLearnComponent,
    // SimilarContentViewMentorComponent,
    // SimilarContentViewSeminarComponent,
    // SimilarContentViewBlogComponent,
    // SimilarContentViewCommunityComponent,
    // LearnCourseCardSimilarContentComponent,
    // MentorCourseCardSimilarContentComponent,
    // SeminarCardSimilarContentComponent,
    // BlogCardSimilarContentComponent,
    // CommunityCardSimilarContentComponent,
    // LearnCourseCardSimilarProfileComponent,
    // SimilarProfileViewComponent,
    // OneTapComponent,
    // EmptyCardComponent,
    // LearnCourseCardComponent,
    // MentorCourseCardComponent,
    // ProjectCardComponent,
    // SeminarCardComponent,
    // ArticalCardComponent,
    // CommunityCardComponent,
    // SkeletonCourseCardComponent,
    // SkeletonCommunityComponent,
    // SkeletonArticalComponent,
    // CommonErrorComponent,
    // UnsubscribeComponent
  ],
  imports: [
    CommonModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    KendoModule
  ],
  exports:[
    //directives
    ClickOutsideDirective,
    ScrollIntoViewDirective,
    MustMatchDirective,
    DateCompareDirective,
    AlphanumericDirective,
    AutoFocusInputDirective,
    NumberCompareDirective,
    DobValidatorDirective,
    DynamicImageDirective,
    //pipes
    SafePipe,
    OrderByPipe,
    EncodeUriComponentPipe,
    MapToArrayFilter,
    TruncateTitleStringPipe,
    // SafeHtmlPipe,
    MinuteSecondsPipe,
    DynamicImageSrcPipe,
    // DynamicImageHandlerPipe,
    EncodeTitleStringPipe,
    AvatarFallbackPipe,
    CompressedImageSrcPipe,
    ImageFallbackPipe,
    CourseEnrollButtonComponent,
    //components
    // DateRangeComponent,
    // NumberRangeComponent,
    // OrderByComponent,
    CurrencySelectComponent,
    // LearnCourseCardHorizontalComponent,
    // LearnCourseCardVerticalComponent,
    // CourseShowInterestButtonComponent,
    // LearnCourseEnrollButtonComponent,
    // MentorCourseCardHorizontalComponent,
    // MentorCourseCardVerticalComponent,
    // SeminarCardVerticalComponent,
    // SemniarCardHorizontalComponent,
    // MentorCourseShowInterestButtonComponent,
    MentorCourseEnrollButtonComponent,
    // SeminarShowInterestButtonComponent,
    SeminarEnrollButtonComponent,
    // PopupSuccessComponent,
    // PopupErrorComponent,
    // PopupWarningComponent,
    // PopupJoinComponent,
    // PopupRecommendComponent,
    // PopupExploreComponent,
    // PopupDiscountComponent,
    // PopupConfirmationComponent,
    // PopupLoadingComponent,
    // PopupVideoComponent,
    // LoadingScreenComponent,
    // ComingSoonComponent,
    // SignNdaComponent,
    // ProfileSummaryPopupComponent,
    // VideoPlayerComponent,
    // BlogClapButtonComponent,
    // ShareDialogComponent,
    // ProfileShareDailogComponent,
    // SitemapComponent,
    // CreateCommunitySuggestionsComponent,
    // MoreFromUserCoursesLearnComponent,
    // MoreFromUserCoursesMentorComponent,
    // MoreFromUserSeminarComponent,
    // MoreFromUserBlogComponent,
    // MoreFromUserCommunityComponent,
    // SimilarContentViewLearnComponent,
    // SimilarContentViewMentorComponent,
    // SimilarContentViewSeminarComponent,
    // SimilarContentViewBlogComponent,
    // SimilarContentViewCommunityComponent,
    // LearnCourseCardSimilarContentComponent,
    // MentorCourseCardSimilarContentComponent,
    // SeminarCardSimilarContentComponent,
    // BlogCardSimilarContentComponent,
    // CommunityCardSimilarContentComponent,
    // SimilarProfileViewComponent,
    // LearnCourseCardSimilarProfileComponent,
    // OneTapComponent,
    // EmptyCardComponent,
    // LearnCourseCardComponent,
    // MentorCourseCardComponent,
    // SeminarCardComponent,
    // ProjectCardComponent,
    // ArticalCardComponent,
    // CommunityCardComponent,
    // SkeletonCourseCardComponent,
    // SkeletonCommunityComponent,
    // SkeletonArticalComponent,
    // CommonErrorComponent,
    // UnsubscribeComponent
  ]
})
export class SharedModule { }
